import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

export default function Seo({
  description,
  lang,
  meta,
  title,
  image,
  twitterCard,
}) {
  const { site, siteFavicon } = useStaticQuery(graphql`
    query SiteMetadata {
      siteFavicon: datoCmsAnglerGlobalSetting {
        favicon {
          url
        }
      }
      site {
        siteMetadata {
          title
          siteUrl
          description
        }
      }
    }
  `);
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`${title} | ${site.siteMetadata.title}`}
      meta={meta}
      description={description}
    >
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image?.url ? image.url : ""} />

      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image?.url ? image.url : ""} />
      <meta name="twitter:card" content={twitterCard} />
      <link
        id="favicon"
        rel="icon"
        href={siteFavicon.favicon.url}
        type="image/x-icon"
      />
    </Helmet>
  );
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};
